import { getStyle } from '../../api.js';
import i18n from '../../i18n.js';
import { notifications } from '@mantine/notifications';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FaCartShopping,
    FaCircleExclamation,
    FaCirclePlus,
    FaHouseChimney
} from 'react-icons/fa6';
import './StyleSelectModal.css';

Modal.setAppElement('#root');

const StyleSelectModal = ({
    ConfirmationModal,
    closeModal,
    genCount,
    handleCheckoutClick,
    handleCheckoutResume,
    handleClose,
    handleConfirmCheckout,
    height,
    isModalOpen,
    isModalVisible,
    sessionID,
    setGenCount,
    setIsFirstLoading,
    setIsModalOpen,
    setItems,
    setStyles,
    styles,
    upload,
    uploadRef,
    width
}) => {
    const base64 = upload.replace(/^data:[^;]+;base64,/, '');
    const [creativeMode, setCreativeMode] = useState(false);
    const [exteriorMode, setExteriorMode] = useState(false);
    const furnitureTypes = process.env.REACT_APP_FURNITURE_TYPES.split(',');
    const sessionLimit = parseInt(process.env.REACT_APP_AMOUNT);
    const [styleCount, setStyleCount] = useState(0);

    const handleAddStyle = (style) => {
        if (styleCount < 10) {
            const update = { ...styles };

            update[style].count = update[style].count + 1;
            setStyles(update);
        } else {
            notifications.show({
                autoClose: 4000,
                color: 'purple',
                icon: <FaCircleExclamation />,
                loading: false,
                message: i18n.t('toast.maxed', {
                    amount: 10
                }),
                title: i18n.t('toast.max'),
                withCloseButton: false
            });
        }

        setGenCount((prevCount) => prevCount + 1);
    };

    const handleFurnitureChange = (furniture, style) => {
        setCreativeMode(true);
        styles[style].furniture = furniture;
    };

    const handleRemoveStyle = (style) => {
        const update = { ...styles };

        if (update[style].count > 0) {
            update[style].count = update[style].count - 1;
        }
        setStyles(update);

        setGenCount((prevCount) => prevCount - 1);
    };

    const handleSubmit = () => {
        setIsFirstLoading(true);
        setIsModalOpen(false);

        if (genCount >= sessionLimit) {
            handleCheckoutResume();
            setGenCount(genCount - sessionLimit);
        }

        Object.keys(styles).forEach((style) => {
            const amount = styles[style].count;
            const furniture = styles[style].furniture;

            for (let i = 0; i < amount; i++) {
                const item = `${style}-${i + 1}`;
                const salt = crypto.getRandomValues(new Uint32Array(1))[0];

                setItems((prevItems) => {
                    return {
                        ...prevItems,
                        [item]: {
                            creative: creativeMode,
                            exterior: exteriorMode,
                            loading: true,
                            furniture: furniture,
                            height: null,
                            image: null,
                            salt: salt,
                            width: null
                        }
                    };
                });

                getStyle(
                    sessionID,
                    exteriorMode ? 'exterior' : 'interior',
                    creativeMode ? 'creative' : 'accurate',
                    furniture,
                    height,
                    base64,
                    `${process.env.REACT_APP_SERVICE}`,
                    style,
                    width
                ).then(async (gen) => {
                    const image = await fetch(gen)
                        .then((response) => response.blob())
                        .then((blob) => URL.createObjectURL(blob));
                    const item = `${style}-${i + 1}`;

                    setItems((prevItems) => {
                        return {
                            ...prevItems,
                            [item]: {
                                ...prevItems[item],
                                height: height,
                                image: image,
                                loading: false,
                                width: width
                            }
                        };
                    });

                    setIsFirstLoading(false);
                });
            }
        });
    };

    const { t } = useTranslation();

    useEffect(() => {
        setStyleCount(
            Object.values(styles).reduce(
                (acc, current) => acc + current.count,
                0
            )
        );
    }, [styles]);

    return (
        <Modal
            className="style-modal"
            contentLabel="Select Styles"
            isOpen={isModalOpen}
            onRequestClose={closeModal}
        >
            <div className="header">
                <h1>
                    <div
                        className="money-box"
                        style={
                            genCount
                                ? {}
                                : {
                                      display: 'none'
                                  }
                        }
                    >
                        <span className="price">
                            ${parseFloat(genCount * 0.5).toFixed(2)}
                        </span>
                        <button
                            className="header-button"
                            onClick={handleCheckoutClick}
                        >
                            <FaCartShopping />
                        </button>
                    </div>
                </h1>
                <>
                    {styleCount ? (
                        <button
                            className="pulse-button"
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {t('generate')}
                        </button>
                    ) : (
                        <button
                            className="upload-button"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                        >
                            <FaCirclePlus className="upload-icon" />
                        </button>
                    )}
                </>
                <>
                    <button
                        className="header-button"
                        onClick={() => {
                            setExteriorMode(!exteriorMode);
                        }}
                        style={{
                            fill: exteriorMode ? '#e3df04' : 'white'
                        }}
                    >
                        <FaHouseChimney />
                    </button>
                </>
                <ConfirmationModal
                    cancelButtonText={t('cancel')}
                    confirmButtonText={t('confirm')}
                    content={t('payment.question')}
                    onCancel={handleClose}
                    onClose={handleClose}
                    onConfirm={handleConfirmCheckout}
                    show={isModalVisible}
                    title={t('payment.title')}
                />
            </div>
            <div className="style-select-gallery">
                <div className="checkbox-container">
                    {Object.keys(styles).map((item) => (
                        <div className="checkbox-container-item" key={item}>
                            <input
                                checked={false}
                                className="checkbox"
                                id={item}
                                name={item}
                                onClick={(event) => {
                                    handleAddStyle(event.target.name);
                                }}
                                type="checkbox"
                            />
                            <label
                                className="checkbox-label"
                                htmlFor={item}
                                name={item}
                                style={{
                                    backgroundImage: exteriorMode
                                        ? `url(${process.env.REACT_APP_THUMBNAILS}/exterior/${encodeURIComponent(item)}.jpg)`
                                        : `url(${process.env.REACT_APP_THUMBNAILS}/interior/${encodeURIComponent(item)}.jpg)`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            >
                                {item.replace('_', ' ').toUpperCase()}
                            </label>
                            {styles[item].count && (
                                <div className="counter-dropdown">
                                    <div className="counter">
                                        <button
                                            className="counter-button"
                                            name={item}
                                            onClick={(event) => {
                                                handleRemoveStyle(
                                                    event.target.name
                                                );
                                            }}
                                        >
                                            -
                                        </button>
                                        <span className="counter-value">
                                            {styles[item].count}
                                        </span>
                                        <button
                                            className="counter-button"
                                            name={item}
                                            onClick={(event) => {
                                                handleAddStyle(
                                                    event.target.name
                                                );
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                    <div className="furniture-dropdown">
                                        <select
                                            aria-placeholder={t('furniture')}
                                            id={`${item}-furniture`}
                                            key={item}
                                            onChange={(event) =>
                                                handleFurnitureChange(
                                                    event.target.value,
                                                    item
                                                )
                                            }
                                            value={styles[item].furniture}
                                        >
                                            <option value="" disabled selected>
                                                {t('furniture')}
                                            </option>
                                            {furnitureTypes.map((type) => (
                                                <option key={type} value={type}>
                                                    {type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        right: '0.25em',
                        top: '0.25em'
                    }}
                ></div>
            </div>
        </Modal>
    );
};

StyleSelectModal.propTypes = {
    ConfirmationModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    genCount: PropTypes.number.isRequired,
    handleCheckoutClick: PropTypes.func.isRequired,
    handleCheckoutResume: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirmCheckout: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    isModalVisible: PropTypes.bool.isRequired,
    sessionID: PropTypes.string.isRequired,
    setGenCount: PropTypes.func.isRequired,
    setIsFirstLoading: PropTypes.func.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    setItems: PropTypes.func.isRequired,
    setStyles: PropTypes.func.isRequired,
    styles: PropTypes.objectOf(
        PropTypes.shape({
            count: PropTypes.number,
            furniture: PropTypes.oneOfType([PropTypes.string, PropTypes.null])
        })
    ).isRequired,
    upload: PropTypes.string.isRequired,
    uploadRef: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired
};

export default StyleSelectModal;
